import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import React from "react";
import MapRoute from "../components/mapRoute";
import Feedback from "../components/service/feedback";
import MetaTag from "../components/metaTag";
import Footer from "../components/footer";
import cx from "classnames";
import { Directions } from "../components/svg/services";
import { IoTimeOutline } from "react-icons/io5";
import { FaTruck } from "react-icons/fa";
import { MdOutlineLightbulb } from "react-icons/md";
import { transportationofthecarSEO } from "../data/seo";
import * as styles from "../styles/pages/services.module.scss";

function Transportationofthecar() {
  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={transportationofthecarSEO} />
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>Транспортировка авто</h1>
        </div>

        <div className={styles.description}>
          <div className={styles.header}>
            <h3>Перевозка автомобиля любого размера</h3>
          </div>
          <div className={styles.content}>
            <div className={styles.preview}>
              <StaticImage
                src="../images/services/5.jpeg"
                alt="Транспортировка автомобиля"
              />
            </div>
            <div className={styles.info}>
              <p>
                Срочно нужно перевести автомобиль? Компания «Автовоз Логистик»
                работает круглосуточно и готова перевезти автомобили любого
                размера, а также мотоциклы, автобусы и спецтехнику. Экономьте
                время – мы приступаем к заказу сразу после вашего звонка!
              </p>
            </div>
          </div>
        </div>
        <MapRoute />

        <div className={styles.about}>
          <div className={styles.block}>
            <h3>Когда обращаться?</h3>
            <ul className={styles.list}>
              <li>Последствия ДТП</li>
              <li>Нарушение рулевого управления</li>
              <li>Блокировка колес, коробки передач, рулевого механизма</li>
              <li>Неисправности тормозной системы</li>
              <li>Заглох двигатель</li>
              <li>Необходимо перевезти авто в другой город</li>
            </ul>
            <Directions className={styles.background} />
            <div className={styles.hint}>
              <IoTimeOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Совет</h3>
            <p className={styles.text}>
              Эвакуатор можно использовать для доставки автомобиля до автовоза
              или специализированной стоянки, на случаи если необходимо
              перевезти автомобиль в другой город.
              <br />
              <br />
              При транспортировки автомобиля на дальние расстояния выгоднее
              использовать укомплектованный автовоз, чем персональный эвакуатор.
            </p>

            <div className={styles.hint}>
              <MdOutlineLightbulb />
            </div>
          </div>

          <div className={cx(styles.block, styles.blocktworow)}>
            <h3>Вы можете рассчитывать на:</h3>
            <ul className={styles.list}>
              <li>
                <span>Экономию.</span> Наши тарифы в среднем на 20% ниже, чем у
                большинства конкурентов
              </li>
              <li>
                <span>Акции и скидки.</span> Для постоянных клиентов мы снижаем
                цены
              </li>
              <li>
                <span>Безопасность.</span> Гарантируем безопасность на всех
                этапах погрузки-разгрузки и транспортировки
              </li>
            </ul>
            <p className={styles.text}>
              Компания «Автовоз Логистик» всегда выполняет свои обещания –
              подаём эвакуатор без задержек и честно рассчитываем стоимость
              услуг. Среди наших клиентов физические лица, страховые компании,
              предприятия с собственным автопарком.
            </p>
            <div className={styles.hint}>
              <FaTruck />
            </div>
          </div>
        </div>

        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default Transportationofthecar;
